<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="headerTextSearchQuery"
			search-query-label="Header Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeHeaderTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedHeaderProduct"
					label="Product"
					:items="headersTypeDropdown"
					custom-sort
					@change="changeHeaderProduct"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="canCreateUpdateHeaders"
					@click="$emit('open-create-header-footer-form', HEADER)"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Headers
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredHeaders"
					sort-by="id"
					:sort-desc="true"
					@click:row="rowClick"
				>
					<template #item.typeDescription="{ item }">
						{{ headerType(item) }}
					</template>
					<template #item.usage="{ item }">
						{{ `${item.usage} ${headerType(item)}` }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && canCreateUpdateHeaders"
							:tooltip-text="'Edit Header' | useLabels"
							@click="rowClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							:tooltip-text="'View Header' | useLabels"
							@click="rowClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && headerFullPermissions"
							:tooltip-text="'Delete Header' | useLabels"
							@click.stop.prevent="headerIdToBeRemoved = item.id"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="headerIdToBeRemoved"
			:entity-name="headerToBeRemoved"
			entity-type="Header"
			@close="headerIdToBeRemoved = null"
			@delete="deleteHeader"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
	deleteHeader,
	HEADER
} from '../../../../../../shared/utils/api/headers-and-footers.js'
import {
	headersAndFootersTypeIdEnum,
	headersAndFootersTypeIdEnumOptions,
	headersAndFootersTypeDropdown
} from '../../../../../../shared/enums/headers-and-footers.js'
import { sortDropdownWithNullDefault } from '../../../../utils/sorting/sortDropdownWithNullDefault.js'
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../shared/state/brands.js'
import { headerTextSearchQuery, changeHeaderTextSearchQuery, selectedHeaderProduct, changeHeaderProduct } from '../../../../../../shared/state/headers.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
export default {
	components: { SectionCard, DataTable, FilterBar, PrimaryActionButton, Dropdown, ConfirmDeleteModal, IconButton },
	props: {
		headers: {
			type: Array,
			default: () => []
		},
		tableHeaders: {
			type: Array,
			default: () => []
		},
		headersMetaData: {
			type: Array,
			default: () => []
		},
		headerFullPermissions: {
			type: Boolean,
			default: false
		},
		canCreateUpdateHeaders: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			headerTextSearchQuery,
			changeHeaderTextSearchQuery,
			metaDataValueSearchQuery,
			selectedHeaderProduct,
			changeHeaderProduct,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			headersAndFootersTypeIdEnum,
			headersAndFootersTypeDropdown,
			headersAndFootersTypeIdEnumOptions,
			HEADER,
			selectedProduct: null,
			headerIdToBeRemoved: null,
			metadataKeys: []
		}
	},
	computed: {
		...mapGetters('auth', ['userHasUniversalBrand']),
		filteredHeaders () {
			return this.headers.filter(({ name, brandId, typeId, externalMetaData }) => {
				let check = true
				if (headerTextSearchQuery.value) check = name.toLowerCase().includes(headerTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedHeaderProduct.value !== null) check = check && typeId === selectedHeaderProduct.value
				if (this.selectedMetaDataKey !== null) check = check && externalMetaData.some(meta => meta.externalDataId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) {
					check = check &&
					externalMetaData.some(meta => meta.value.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				}
				return check
			})
		},
		headerToBeRemoved () {
			return this.headers.find(({ id }) => id === this.headerIdToBeRemoved).name
		},
		headersTypeDropdown () {
			return headersAndFootersTypeDropdown
				.filter(({ value }) => value !== headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER)
				.sort(sortDropdownWithNullDefault)
		}
	},
	methods: {
		async deleteHeader () {
			await deleteHeader(this.headerIdToBeRemoved)
			this.headerIdToBeRemoved = null
			showSnackbar('Header has been deleted')
			this.$emit('load-headers')
		},
		rowClick (row) {
			if (!this.canCreateUpdateHeaders) {
				row.readOnly = true
			}
			row.brand = row.brandId
			this.$emit('open-edit-header-footer-form', row)
		},
		clearFilters () {
			selectedHeaderProduct.value = null
		},
		headerType (item) {
			return this.headersAndFootersTypeIdEnumOptions[item.typeId]
		}
	}
}
</script>
