<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal
			width="80%"
			min-width="850px"
			max-height="400px"
		>
			<template #modal-title>
				{{ modalHeader }}
				<v-spacer />
				<IconButton
					@click="close"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						:value="translation.languageName"
						label="Language"
						view-only
						disabled
					/>
					<TextField
						:value="translation.name"
						label="Name"
						view-only
						disabled
					/>
					<div
						class="text-subtitle-2 mb-3"
					>
						Default Contents
					</div>
					<div
						v-dompurify-html="translation.html"
						class="mt-2 cassie-disabled-textarea rounded-lg"
					/>
					<FroalaEditor
						v-if="!translation.readOnly"
						v-model="translationHtml"
						title="Translated Contents"
					/>
					<div
						v-else
					>
						<div>
							<p class="text-subtitle-2 mb-3">
								Translated Contents
							</p>
						</div>
						<div
							v-dompurify-html="translationHtml"
							class="mt-2 cassie-disabled-textarea rounded-lg"
						/>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="translation.readOnly || error.length > 0"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import FroalaEditor from '../../shared/froala-editor.vue'
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { createHeaderTranslation, updateHeaderTranslation, createFooterTranslation, updateFooterTranslation }
from '../../../../../shared/utils/api/headers-and-footers.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: { FroalaEditor, TextField, PrimaryActionButton, SecondaryActionButton, IconButton, Modal },
	props: {
		translation: Object,
		type: String
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			translationHtml: ''
		}
	},
	computed: {
		isEdit () {
			return !!this.translation.translatedHtml && this.translation.headerTranslationId || !!this.translation.translatedHtml && this.translation.footerTranslationId
		},
		modalHeader () {
			if (this.translation.readOnly) {
				return `View ${this.type} Translation`
			}
			return `${this.translation.translatedHtml ? 'Edit' : 'Create'} ${this.type} Translation`
		},
		error () {
			if (this.translationHtml.length > 4000 || this.translationHtml.length < 5) {
				return 'Translated contents cannot be empty, and it needs to be in a range of 5 to 4000 characters.'
			}
			return ''
		}
	},
	created () {
		if (this.translation.translatedHtml) {
			this.translationHtml = this.translation.translatedHtml ?? ''
		}
	},
	methods: {
		close () {
			this.$emit('close', false)
		},
		async submit () {
			// sample request
			if (this.error.length > 0) {
				showSnackbar({
					message: this.error,
					color: 'error'
				})
				return
			}
			const isHeader = this.type === 'Header'
			if (isHeader) {
				if (this.isEdit) {
				// Edit Request
					const updateHeaderTranslationRequest = {
						languageId: this.translation.languageId,
						headerTranslationId: this.translation.headerTranslationId,
						headerHtml: this.translationHtml
					}
					await updateHeaderTranslation(updateHeaderTranslationRequest).then(() => {
						this.$emit('close', true)
					}).catch(err => {
						const errorMessage = err.response.data[0]
						showSnackbar({
							text: errorMessage == null ? 'Failed to update header translation' : errorMessage,
							color: 'error'
						})
					})
				} else {
				// Create Request
					const createHeaderTranslationRequest = {
						languageId: this.translation.languageId,
						headerId: this.translation.id,
						headerHtml: this.translationHtml
					}
					await createHeaderTranslation(createHeaderTranslationRequest).then(() => {
						this.$emit('close', true)
					}).catch(err => {
						const errorMessage = err.response.data[0]
						showSnackbar({
							text: errorMessage == null ? 'Failed to create header translation' : errorMessage,
							color: 'error'
						})
					})
				}
			}
			if (!isHeader) {
				if (this.isEdit) {
				// Edit Request
					const updateFooterTranslationRequest = {
						languageId: this.translation.languageId,
						footerTranslationId: this.translation.footerTranslationId,
						footerHtml: this.translationHtml
					}
					await updateFooterTranslation(updateFooterTranslationRequest).then(() => {
						this.$emit('close', true)
					}).catch(err => {
						const errorMessage = err.response.data[0]
						showSnackbar({
							text: errorMessage == null ? 'Failed to update footer translation' : errorMessage,
							color: 'error'
						})
					})
				} else {
				// Create Request
					const createFooterTranslationRequest = {
						languageId: this.translation.languageId,
						footerId: this.translation.id,
						footerHtml: this.translationHtml
					}
					await createFooterTranslation(createFooterTranslationRequest).then(() => {
						this.$emit('close', true)
					}).catch(err => {
						const errorMessage = err.response.data[0]
						showSnackbar({
							text: errorMessage == null ? 'Failed to create footer translation' : errorMessage,
							color: 'error'
						})
					})
				}
			}
		}
	}
}
</script>

<style lang="scss">

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
	border-radius: 10px;
}

</style>
