<template>
	<ValidationForm #default="{ handleSubmit, reset }">
		<Modal
			width="80%"
			min-width="850px"
			max-height="400px"
		>
			<template #modal-title>
				<div class="cassie-vertical-sm">
					{{ title }}
					<div
						v-if="editMode && formData.createdByName"
						class="d-flex flex-row"
					>
						<div
							class="text-caption"
						>
							Created By: {{ formData.createdByName }}
							{{ formData.createdDate }}
							<span
								v-if="formData.lastEditedDate && formData.editedByName !== ' '"
							>
								| Last Edited By: {{ formData.editedByName }}
								{{ formData.lastEditedDate }}
							</span>
						</div>
					</div>
				</div>
				<v-spacer />
				<IconButton
					@click="close(reset)"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div
					class="cassie-vertical-md"
				>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-if="showTypeDropdown"
							v-model="formData.type"
							:label="'Choose Component *' | useLabels"
							class="cassie-input-width-xl"
							:items="[{ value: HEADER, text: 'Header' }, { value: FOOTER, text: 'Footer'}]"
							rules="required"
						/>
						<Dropdown
							v-model="formData.brand"
							:disabled="!hasPermissions || editMode || !clientMultiBranded || singleBrand"
							:label="'Assign to Brand*' | useLabels"
							class="cassie-input-width-xl"
							:items="filteredBrandOptions"
							custom-sort
							:rules="{ required: clientMultiBranded}"
						/>
						<Dropdown
							v-if="!typeId"
							v-model="formData.typeId"
							class="cassie-input-width-xl"
							:disabled="!hasPermissions || editMode"
							:items="headerFooterTypeOptions"
							label="Product *"
							rules="required"
						/>
					</div>
					<div class="d-flex flex-row">
						<TextField
							v-model="formData.name"
							class="cassie-input-width-xl"
							:disabled="(!hasPermissions || readOnly)"
							label="Name *"
							:rules="{required: true, max: 120}"
						/>
					</div>
					<div class="d-flex flex-column">
						<FroalaEditor
							v-if="hasPermissions && !readOnly"
							v-model="formData.html"
							class="cassie-input-width-xl"
							:rules="{required: true, max: 2000}"
							title="Contents *"
						/>
						<v-textarea
							v-else
							v-dompurify-html="formData.html"
							light
							dense
							outlined
							readonly
							class="mt-2 cassie-disabled-textarea cassie-input-width-xl"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<v-spacer />
						<TextButton
							:disabled="readOnly || !hasPermissions"
							@click="openMetaDataForm(reset)"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Add Metadata
						</TextButton>
					</div>
					<div v-show="showAddMetaDataForm">
						<div class="cassie-vertical-md">
							<Dropdown
								v-model="selectedMetaDataKey"
								:items="dropdownMetaDataOptions"
								:disabled="!addingMetaData"
								return-object
								label="Metadata Key *"
								class="cassie-input-width-xl"
							/>
							<Dropdown
								v-if="displayMetadataDropdown"
								v-model="newMetaDataValue"
								:items="metaDataDropdownItems"
								label="Metadata Value *"
								class="cassie-input-width-xl"
								:rules="{ required: showAddMetaDataForm && selectedMetaDataKey !== null }"
							/>
							<TextField
								v-else
								v-model="newMetaDataValue"
								label="Metadata Value *"
								class="cassie-input-width-xl"
								:rules="{required: showAddMetaDataForm && selectedMetaDataKey !== null, max: 255}"
							/>
							<div class="d-flex flex-row cassie-horizontal-sm">
								<v-spacer />
								<SecondaryActionButton
									@click="showAddMetaDataForm = !showAddMetaDataForm"
								>
									Cancel
								</SecondaryActionButton>
								<PrimaryActionButton
									v-show="addingMetaData"
									:disabled="!selectedMetaDataKey || !newMetaDataValue || !hasPermissions"
									@click="handleSubmit(addMetaData)"
								>
									Add Metadata
								</PrimaryActionButton>
								<PrimaryActionButton
									v-show="!addingMetaData"
									:disabled="!selectedMetaDataKey || !newMetaDataValue || !hasMetaDataPermission || !hasPermissions"
									@click="handleSubmit(updateMetaData)"
								>
									Update Metadata
								</PrimaryActionButton>
							</div>
						</div>
					</div>
					<div>
						<DataTable
							:headers="metaDataTableHeaders"
							:items="formData.externalMetaData"
							@click:row="metaDataEditClick"
						>
							<template #item.action="{ item }">
								<IconButton
									:disabled="!hasPermissions"
									class="mr-2"
									tooltip-text="Edit Metadata"
									small
									@click.stop="metaDataEditClick(item)"
								>
									{{ readOnly ? 'mdi-eye' : 'mdi-pencil' }}
								</IconButton>
								<IconButton
									:disabled="!hasPermissions"
									tooltip-text="Delete Metadata"
									small
									@click.stop="deleteMetaData(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</div>
					<div
						v-if="formData.associatedConsentBanners && formData.associatedConsentBanners.length !== 0"
						class="d-flex flex-column"
					>
						<div class="text-subtitle-2">
							Associated Consent Banners
						</div>
						<v-simple-table dense>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Name
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="banner in (formData.associatedConsentBanners ? formData.associatedConsentBanners : [])"
										:key="banner"
									>
										<td>
											{{ banner }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton @click="close(reset)">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="!hasPermissions || readOnly"
					@click="handleSubmit(submit)"
				>
					{{ !editMode ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import {
	createFooter,
	createHeader,
	FOOTER,
	HEADER,
	updateFooter,
	updateHeader
} from '../../../../shared/utils/api/headers-and-footers.js'
import DataTable from '../../../../shared/components/data-table.vue'
import {
	CAN_CREATE_UPDATE_CONSENT_BANNERS,
	CAN_CREATE_AND_UPDATE_HEADERS,
	CAN_CREATE_UPDATE_FOOTERS,
	COMPONENTS_MODULE_FULL_PERMISSIONS,
	META_DATA_KEYS_MODULE_FULL_PERMISSIONS,
	CAN_CREATE_UPDATE_COOKIE_BANNERS
} from '../../../../shared/permissions/admin-portal-permissions.js'
import Modal from '../../../../shared/components/modal.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import TextField from '../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import ValidationForm from '../../../../shared/components/validation-form.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import FroalaEditor from './froala-editor.vue'
import { defaultBrandInSelect, singleBrand, clientMultiBranded, brandOptions, waitForLoad, UNIVERSAL_BRAND_ITEM } from '../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../shared/state/snackbar.js'
import TextButton from '../../../../shared/components/text-button.vue'
import {
	headersAndFootersTypeIdEnum,
	headersAndFootersTypeIdEnumOptions
} from '../../../../shared/enums/headers-and-footers.js'
import { COMPONENT_TYPE } from '../../../../shared/state/meta-data.js'
import { getExternalMetaData } from '../../../../shared/utils/api/external-meta-data.js'
const UNIVERSAL_BRAND = 0
export default {
	components: {
		Modal,
		Dropdown,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton,
		TextButton,
		FroalaEditor,
		DataTable,
		ValidationForm
	},
	props: {
		formToEdit: {
			type: Object
		},
		typeId: {
			type: Number
		},
		formType: {
			type: String,
			required: true
		},
		selectedBrandId: Number,
		showTypeDropdown: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			COMPONENT_TYPE,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			newMetaDataValue: '',
			selectedMetaDataKey: null,
			showAddMetaDataForm: false,
			addingMetaData: true,
			oldMetaDataValue: '',
			UNIVERSAL_BRAND,
			headersAndFootersTypeIdEnumOptions,
			headerFooterTypes: [HEADER, FOOTER],
			formData: this.formToEdit
				? JSON.parse(JSON.stringify(this.formToEdit))
				: {
					type: this.formType,
					id: null,
					name: '',
					viewOnly: false,
					html: '',
					associatedConsentBanners: [],
					brand: defaultBrandInSelect.value,
					createdByName: '',
					createdDate: '',
					editedByName: '',
					lastEditedDate: '',
					externalMetaData: []
				},
			dropdownMetaData: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userBrands']),
		editMode () {
			return !!this.formToEdit
		},
		multiBrandingEnabled () {
			return brandOptions.value.length > 1
		},
		headerFooterTypeOptions () {
			return Object.entries(headersAndFootersTypeIdEnumOptions)
				.map(entry => ({ value: Number(entry[0]), text: entry[1] }))
				.filter(entry => (entry.value !== 4 && this.formData.type === 'Header') || this.formData.type === 'Footer')
		},
		title () {
			const isFooter = this.formData.type === FOOTER
			if (this.readOnly) {
				return 'View ' + this.formData.type
			}
			return `${this.editMode ? 'Edit' : 'Create'}
				${isFooter ? 'Page ' : ''}${this.formData.type ?? ''
}`
		},
		dropdownMetaDataOptions () {
			const metaDataItems = this.dropdownMetaData?.map(metaData => ({
				value: metaData.id,
				text: metaData.name,
				associatedComponentTypes: metaData.associatedComponentTypes,
				dropdownItems: metaData.dropDownValues
			})) ?? []

			if (this.formData.type === HEADER) {
				return metaDataItems.filter(({ associatedComponentTypes }) => associatedComponentTypes.includes(COMPONENT_TYPE.HEADERS))
			} else {
				return metaDataItems.filter(({ associatedComponentTypes }) => associatedComponentTypes.includes(COMPONENT_TYPE.FOOTERS))
			}
		},
		metaDataDropdownItems () {
			return this.dropdownMetaDataOptions.find(({ value }) => value === this.selectedMetaDataKey?.value)?.dropdownItems.map(dropdown => {
				return dropdown.value
			}).filter(dropdown => !this.formData.externalMetaData.find(({ externalDataId, value }) => externalDataId === this.selectedMetaDataKey.value && value === dropdown))
		},
		displayMetadataDropdown () {
			return !!this.dropdownMetaDataOptions.find(({ value }) => value === this.selectedMetaDataKey?.value)?.dropdownItems.length >= 1
		},
		hasPermissions () {
			return (this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS) || (this.typeId != null && this.typeId !== headersAndFootersTypeIdEnum.CONSENT_BANNERS) ||
				(this.productAreaPermission(CAN_CREATE_AND_UPDATE_HEADERS) && this.formData.type === HEADER) ||
				(this.productAreaPermission(CAN_CREATE_UPDATE_FOOTERS) && this.formData.type === FOOTER) ||
				(this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS)) ||
				(this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_BANNERS))
			)
		},
		hasMetaDataPermission () {
			return this.productAreaPermission(META_DATA_KEYS_MODULE_FULL_PERMISSIONS)
		},
		readOnly () {
			return !this.userBrands.includes(this.formData.brandId) && !this.userBrands.includes(UNIVERSAL_BRAND) && this.editMode
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.editMode) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		externalMetaDataFormInProgress () {
			return this.selectedMetaDataKey !== null && this.newMetaDataValue.length > 0
		},
		metaDataTableHeaders () {
			return [
				{
					text: 'Metadata Key',
					value: 'name',
					width: '40%'
				},
				{
					text: 'Metadata Value',
					value: 'value',
					width: '40%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '20%'
				}
			]
		}
	},
	async created () {
		await waitForLoad()
		if (this.filteredBrandOptions.length === 1 && !this.editMode) {
			this.formData.brand = this.filteredBrandOptions[0].value
		}
		if (this.selectedBrandId && this.selectedBrandId !== null) {
			this.formData.brand = this.selectedBrandId
		}

		if (this.editMode) {
			this.formData.brand = this.formToEdit.brand ?? this.selectedBrandId
		}
		this.getMetaDataKeys()
	},
	methods: {
		async submit () {
			if (this.formData.type === HEADER) {
				const newHeader = {
					headerId: this.formData.id,
					headerName: this.formData.name,
					headerHtml: this.formData.html,
					brandId: this.formData.brand || 0,
					typeId: this.typeId || this.formData.typeId,
					externalValues: this.formData.externalMetaData?.map(x => {
						return {
							externalMetaDataKeyId: x.externalDataId,
							externalFieldValue: x.value
						}
					})
				}
				if (this.editMode) {
					await updateHeader(newHeader)
				} else {
					await createHeader(newHeader).then(result => {
						const selectedHeaderId = result.data.newHeaderId
						this.$emit('set-selected-header:headerId', selectedHeaderId)
					})
				}
			} else if (this.formData.type === FOOTER) {
				const newFooter = {
					footerId: this.formData.id,
					footerName: this.formData.name,
					footerHtml: this.formData.html,
					brandId: this.formData.brand || 0,
					typeId: this.typeId || this.formData.typeId,
					externalValues: this.formData.externalMetaData?.map(x => {
						return {
							externalMetaDataKeyId: x.externalDataId,
							externalFieldValue: x.value
						}
					})
				}
				if (this.editMode) {
					await updateFooter(newFooter)
				} else {
					await createFooter(newFooter).then(result => {
						const selectedFooterId = result.data.newFooterId
						this.$emit('set-selected-footer:footerId', selectedFooterId)
					})
				}
			}
			showSnackbar(this.editMode ? `${this.formData.type} updated successfully` : `New ${this.formData.type} created successfully`)
			this.$emit('updated', this.editMode)
			this.close()
		},
		async getMetaDataKeys () {
			const { externalMetaData } = await getExternalMetaData()
			this.dropdownMetaData = externalMetaData
		},
		async openMetaDataForm (reset) {
			reset()
			this.addingMetaData = true
			this.showAddMetaDataForm = !this.showAddMetaDataForm
			if (!this.showAddMetaDataForm) {
				this.selectedMetaDataKey = null
				this.newMetaDataValue = ''
			}
		},
		deleteMetaData ({ externalDataId, name, value }) {
			this.formData.externalMetaData = this.formData.externalMetaData
				.filter(
					x => x.externalDataId !== externalDataId ||
					x.name !== name ||
					x.value !== value)
		},
		addMetaData () {
			const duplicateKeyAndValue = this.formData.externalMetaData.find(
				({ externalDataId, value }) => externalDataId === this.selectedMetaDataKey.value && value === this.newMetaDataValue
			)
			if (duplicateKeyAndValue) {
				showSnackbar({ color: 'red', text: 'Combination of this metadata key and value already exists' })
				return
			} else {
				this.formData.externalMetaData.push({
					externalDataId: this.selectedMetaDataKey.value,
					name: this.selectedMetaDataKey.text,
					value: this.newMetaDataValue
				})
			}

			showSnackbar(`New ${this.newMetaDataValue} created successfully`)

			this.selectedMetaDataKey = null
			this.newMetaDataValue = ''
		},
		updateMetaData () {
			const result = this.formData.externalMetaData.find(item => item.externalDataId === this.selectedMetaDataKey.value && item.value === this.oldMetaDataValue)
			if (result) {
				result.value = this.newMetaDataValue
			}

			showSnackbar(`New ${this.newMetaDataValue} updated successfully`)

			this.selectedMetaDataKey = null
			this.newMetaDataValue = ''
			this.showAddMetaDataForm = false
		},
		close () {
			this.$emit('close')
		},
		async openMetaDataFormEdit (row) {
			this.addingMetaData = false
			this.showAddMetaDataForm = this.showAddMetaDataForm = true
			if (this.showAddMetaDataForm) {
				this.selectedMetaDataKey = { value: row.externalDataId, text: row.name }
				this.newMetaDataValue = row.value
				this.oldMetaDataValue = row.value
			} else {
				this.selectedMetaDataKey = null
				this.newMetaDataValue = ''
				this.oldMetaDataValue = ''
			}
		},
		metaDataEditClick (row) {
			this.openMetaDataFormEdit(row)
		}
	}
}
</script>

<style lang="scss">

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
}

</style>
