<template>
	<ViewLayout>
		<template #header-title>
			Headers
		</template>
		<template #header-caption>
			Add, edit and manage headers and translations
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<HeadersTab
						:headers="headers"
						:table-headers="tableHeaders"
						:translation-table-headers="translationTableHeaders"
						:header-translations="headerTranslations"
						:assigned-languages="assignedLanguagesItems"
						:header-full-permissions="headerFullPermissions"
						:can-create-update-headers="canCreateUpdateHeaders"
						@open-create-header-footer-form="openCreateHeaderFooterForm"
						@load-headers="loadHeaders"
						@open-edit-header-footer-form="openEditHeaderFooterForm"
					/>
				</template>
				<template #1>
					<HeadersTranslationsTab
						:translation-table-headers="translationTableHeaders"
						:header-translations="headerTranslations"
						:assigned-languages="assignedLanguagesItems"
						:header-full-permissions="headerFullPermissions"
						:can-create-update-headers="canCreateUpdateHeaders"
						@load-headers="loadHeaders"
					/>
				</template>
			</TabItems>
			<HeaderFooterFormModal
				v-if="showHeaderFooterForm"
				:form-to-edit="headerFooterFormData"
				form-type="Header"
				@updated="loadHeaders"
				@close="() => {
					showHeaderFooterForm = false
					headerFooterFormData = null
				}"
			/>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import HeadersTab from './headers-tab.vue'
import HeadersTranslationsTab from './header-translations.vue'
import { getHeaders, HEADER, getHeaderTranslations } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { headersAndFootersTypeIdEnumOptions } from '../../../../../../shared/enums/headers-and-footers.js'
import { CAN_CREATE_AND_UPDATE_HEADERS, COMPONENTS_MODULE_FULL_PERMISSIONS, HEADERS_FOOTERS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import HeaderFooterFormModal from '../../../shared/header-footer-form-modal.vue'
import { changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'

export default {
	components: {
		HeaderFooterFormModal,
		HeadersTab,
		HeadersTranslationsTab,
		ViewLayout,
		TabItems,
		Tabs
	},
	setup () {
		return {
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			tabs: [{ title: 'Headers', slot: '0' }, { title: 'Header Translations', slot: '1' }],
			tab: '0',
			headers: [],
			headerTranslations: [],
			assignedLanguages: [],
			showFilters: false,
			searchQuery: null,
			selectedBrandId: null,
			showHeaderFooterForm: false,
			headerFooterFormData: null,
			selectedFormType: null,
			selectedProductTypeId: null,
			selectedType: null,
			selectedLanguageId: null,
			headersAndFootersTypeIdEnumOptions,
			HEADER
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		canCreateUpdateHeaders () {
			return this.checkPermission(CAN_CREATE_AND_UPDATE_HEADERS)
		},
		headerFullPermissions () {
			return this.checkPermission(HEADERS_FOOTERS_MODULE_FULL_PERMISSIONS || COMPONENTS_MODULE_FULL_PERMISSIONS)
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '10%'
				},
				{
					text: 'Header Name',
					value: 'name',
					width: '20%'
				},
				{
					text: 'Product',
					value: 'typeDescription',
					width: '20%'
				},
				{
					text: 'Usage',
					value: 'usage',
					width: '20%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
		},
		translationTableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '20%'
				},
				{
					text: 'Header Name',
					value: 'name',
					width: '20%'
				},
				{
					text: 'Product',
					value: 'typeDescription',
					width: '20%'
				},
				{
					text: 'Translation Status',
					value: 'translationStatus',
					width: '10%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
		}
	},
	async created () {
		this.loadHeaders()
		const { data: assignedLanguages } = await getAssignedLanguages()
		this.assignedLanguages = assignedLanguages
	},
	methods: {
		openEditHeaderFooterForm (item) {
			this.showHeaderFooterForm = true
			this.headerFooterFormData = item
		},
		openCreateHeaderFooterForm (formType) {
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
		},
		async loadHeaders () {
			const { data: headers } = await getHeaders()
			const { headerTranslations } = await getHeaderTranslations()
			this.headerTranslations = headerTranslations
			this.headers = headers
		}
	}
}
</script>
